var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb100p"},[_c('h1',[_vm._v("Sales and Order Goals")]),_c('Accordion',[_c('AccordionItem',{staticClass:"p10p"},[_c('template',{slot:"accordion-trigger"},[_c('table',{staticClass:"accordion-table"},[_c('tr',[_c('th',{staticClass:"bold",attrs:{"width":"70%"}},[_vm._v("CDI Total")]),_c('th'),_c('td',[_c('b',[_vm._v("Sales Goal Year Total:")]),_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalProdYear)))]),_c('td'),_c('td',[_c('b',[_vm._v("Order Goal Year Total: ")]),_vm._v(_vm._s(_vm._f("currency")(_vm.totalIncYear)))]),_c('td')]),_c('tr',{staticClass:"mt100p"},[_c('td')])])]),_c('template',{slot:"accordion-content"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"table table-responsive"},[(Object.keys(_vm.goalData).length)?_c('table',{staticClass:"table table-condensed"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Month")]),_c('th',{staticClass:"number"},[_vm._v("Working Days")]),_c('th',{staticClass:"number"},[_vm._v("Sales Goal")]),_c('th',{staticClass:"number"},[_vm._v("Sales Goal/Day")]),_c('th',{staticClass:"number"},[_vm._v("Order Goal")]),_c('th',{staticClass:"number"},[_vm._v("Order Goal/Day")])])]),_c('tbody',_vm._l((_vm.months),function(ref){
var monthId = ref[0];
var month = ref[1];
return _c('tr',{key:monthId,attrs:{"id":monthId}},[_c('td',[_vm._v(_vm._s(month))]),_c('td',{staticClass:"number"},[_vm._v(_vm._s(_vm.goalData[_vm.currentYear][monthId][_vm.location]['production'].days_in_month)+" ")]),_c('td',{staticClass:"number"},[_vm._v(_vm._s(_vm._f("currency")(_vm.totalProd[monthId - 1])))]),_c('td',{staticClass:"number"},[_vm._v(_vm._s(_vm._f("currency")(_vm.totalProd[monthId - 1] / _vm.goalData[_vm.currentYear][monthId][_vm.location]['production'].days_in_month))+" ")]),_c('td',{staticClass:"number"},[_vm._v(_vm._s(_vm._f("currency")(_vm.totalInc[monthId - 1])))]),_c('td',{staticClass:"number"},[_vm._v(_vm._s(_vm._f("currency")(_vm.totalInc[monthId - 1] / _vm.goalData[_vm.currentYear][monthId][_vm.location]['incoming'].days_in_month))+" ")])])}),0)]):_vm._e()])])])],2)],1),_vm._l((_vm.cache.cdiLocationCache.CDI_LOCATIONS),function(ref){
var cdiLocationId = ref[0];
var cdiLocation = ref[1];
return _c('Accordion',{key:cdiLocationId},[_c('AccordionItem',{staticClass:"p10p"},[_c('template',{slot:"accordion-trigger"},[_c('table',{staticClass:"accordion-table"},[_c('tr',[_c('th',{staticClass:"bold",attrs:{"width":"70%"}},[_vm._v(_vm._s(cdiLocation))]),_c('th')]),_c('tr',[_c('td',[_c('b',[_vm._v("Sales Goal Year Total:")]),_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalProdMonth[cdiLocationId - 1])))]),_c('td',[_c('b',[_vm._v("Order Goal Year Total:")]),_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalIncMonth[cdiLocationId - 1])))])]),_c('tr',{staticClass:"mt100p"},[_c('td')])])]),_c('template',{slot:"accordion-content"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"table table-responsive"},[(Object.keys(_vm.goalData).length)?_c('table',{staticClass:"table"},[_c('thead',[(_vm.isActive)?_c('button',{staticClass:"margin_bottom",on:{"click":function($event){_vm.showDetails = !_vm.showDetails}}},[_vm._v("Edit Goals")]):_vm._e(),_c('tr',{staticClass:"number"},[_c('th',[_vm._v("Month")]),_c('th',{staticClass:"number"},[_vm._v("Working Days")]),_c('th',{staticClass:"number"},[_vm._v("Sales Goal")]),_c('th',{staticClass:"number"},[_vm._v("Sales Goal/Day")]),_c('th',{staticClass:"number"},[_vm._v("Order Goal")]),_c('th',{staticClass:"number"},[_vm._v("Order Goal/Day")])])]),_c('tbody',_vm._l((_vm.months),function(ref){
var monthId = ref[0];
var month = ref[1];
return _c('tr',{key:monthId,attrs:{"id":monthId}},[_c('td',[_vm._v(_vm._s(month))]),_c('td',{staticClass:"number"},[_vm._v(_vm._s(_vm.goalData[_vm.currentYear][monthId][cdiLocationId]['production'].days_in_month)+" ")]),(_vm.showDetails)?_c('td',{staticClass:"number"},[_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-addon"},[_vm._v("$")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.goalData[_vm.currentYear][monthId][cdiLocationId]['production'].goal),expression:"goalData[currentYear][monthId][cdiLocationId]['production'].goal"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.goalData[_vm.currentYear][monthId][cdiLocationId]['production'].goal)},on:{"change":function($event){return _vm.save(
                                                       _vm.currentYear,
                                                       monthId,
                                                       316,
                                                       _vm.goalData[_vm.currentYear][monthId][cdiLocationId]['production'].goal,
                                                       cdiLocationId
                                                   )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.goalData[_vm.currentYear][monthId][cdiLocationId]['production'], "goal", $event.target.value)}}})])]):_c('td',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.goalData[_vm.currentYear][monthId][cdiLocationId]['production'].goal))+" ")]),_c('td',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.goalData[_vm.currentYear][monthId][cdiLocationId]['production'].goal / _vm.goalData[_vm.currentYear][monthId][cdiLocationId]['production'].days_in_month))+" ")]),(_vm.showDetails)?_c('td',{staticClass:"number"},[_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-addon"},[_vm._v("$")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.goalData[_vm.currentYear][monthId][cdiLocationId]['incoming'].goal),expression:"goalData[currentYear][monthId][cdiLocationId]['incoming'].goal"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.goalData[_vm.currentYear][monthId][cdiLocationId]['incoming'].goal)},on:{"change":function($event){return _vm.save(
                                                       _vm.currentYear,
                                                       monthId,
                                                       315,
                                                       _vm.goalData[_vm.currentYear][monthId][cdiLocationId]['incoming'].goal,
                                                       cdiLocationId

                                                   )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.goalData[_vm.currentYear][monthId][cdiLocationId]['incoming'], "goal", $event.target.value)}}})])]):_c('td',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.goalData[_vm.currentYear][monthId][cdiLocationId]['incoming'].goal))+" ")]),_c('td',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.goalData[_vm.currentYear][monthId][cdiLocationId]['incoming'].goal / _vm.goalData[_vm.currentYear][monthId][cdiLocationId]['incoming'].days_in_month))+" ")])])}),0)]):_vm._e()])])])],2)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }