<template>
    <div class="mb100p">
        <h1>Sales and Order Goals</h1>
        <Accordion>
            <AccordionItem class="p10p">
                <template slot="accordion-trigger">
                    <table class="accordion-table">
                        <tr>
                            <th class="bold" width="70%">CDI Total</th>
                            <th></th>
                            <td><b>Sales Goal Year Total:</b> {{ totalProdYear | currency }}</td>
                            <td></td>
                            <td><b>Order Goal Year Total: </b>{{ totalIncYear | currency }}</td>
                            <td></td>
                        </tr>
                        <tr class="mt100p">
                            <td></td>
                        </tr>
                    </table>
                </template>
                <template slot="accordion-content">
                    <div class="content">
                        <div class="table table-responsive">

                            <table class="table table-condensed" v-if="Object.keys(goalData).length">
                                <thead>
                                <tr>
                                    <th>Month</th>
                                    <th class="number">Working Days</th>
                                    <th class="number">Sales Goal</th>
                                    <th class="number">Sales Goal/Day</th>
                                    <th class="number">Order Goal</th>
                                    <th class="number">Order Goal/Day</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="[monthId, month] in months" :key="monthId" v-bind:id="monthId">
                                    <td>{{ month }}</td>
                                    <td class="number">{{
                                            goalData[currentYear][monthId][location]['production'].days_in_month
                                        }}
                                    </td>
                                    <td class="number">{{ totalProd[monthId - 1] | currency}}</td>
                                    <td class="number">{{
                                            totalProd[monthId - 1] / goalData[currentYear][monthId][location]['production'].days_in_month | currency
                                        }}
                                    </td>
                                    <td class="number">{{ totalInc[monthId - 1] | currency}}</td>

                                    <td class="number">{{
                                            totalInc[monthId - 1] / goalData[currentYear][monthId][location]['incoming'].days_in_month | currency
                                        }}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </template>
            </AccordionItem>
        </Accordion>
        <Accordion v-for="[cdiLocationId, cdiLocation] in cache.cdiLocationCache.CDI_LOCATIONS"
                   :key="cdiLocationId">
            <AccordionItem class="p10p">
                <template slot="accordion-trigger">
                    <table class="accordion-table">
                        <tr>
                            <th class="bold" width="70%">{{ cdiLocation }}</th>
                            <th></th>
                        </tr>
                        <tr>
                            <td><b>Sales Goal Year Total:</b> {{ totalProdMonth[cdiLocationId - 1] | currency }}</td>
                            <td><b>Order Goal Year Total:</b> {{ totalIncMonth[cdiLocationId - 1] | currency }}</td>
                        </tr>
                        <tr class="mt100p">
                            <td></td>
                        </tr>
                    </table>

                </template>
                <template slot="accordion-content">
                    <div class="content">
                        <div class="table table-responsive">
                            <table class="table" v-if="Object.keys(goalData).length">
                                <thead>
                                <button class="margin_bottom" v-if="isActive" @click="showDetails = !showDetails">Edit Goals</button>
                                <tr class="number">
                                    <th>Month</th>
                                    <th class="number">Working Days</th>
                                    <th class="number">Sales Goal</th>
                                    <th class="number">Sales Goal/Day</th>
                                    <th class="number">Order Goal</th>
                                    <th class="number">Order Goal/Day</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="[monthId, month] in months" :key="monthId" v-bind:id="monthId">
                                    <td>{{ month }}</td>
                                    <td class="number">{{
                                            goalData[currentYear][monthId][cdiLocationId]['production'].days_in_month
                                        }}
                                    </td>
                                    <td v-if="showDetails" class="number">
                                        <div class="input-group">
                                            <div class="input-group-addon">$</div>
                                            <input  class="form-control"
                                                    @change="save(
                                                           currentYear,
                                                           monthId,
                                                           316,
                                                           goalData[currentYear][monthId][cdiLocationId]['production'].goal,
                                                           cdiLocationId,
                                                       )"
                                                    type="text"
                                                    v-model="goalData[currentYear][monthId][cdiLocationId]['production'].goal"
                                            />
                                        </div>
                                    </td>
                                    <td v-else class="number">
                                        {{goalData[currentYear][monthId][cdiLocationId]['production'].goal | currency}}
                                    </td>
                                    <td class="number">
                                        {{goalData[currentYear][monthId][cdiLocationId]['production'].goal / goalData[currentYear][monthId][cdiLocationId]['production'].days_in_month | currency}}
                                    </td>
                                    <td v-if="showDetails" class="number">
                                        <div class="input-group">
                                            <div class="input-group-addon">$</div>
                                            <input class="form-control"
                                                   @change="save(
                                                           currentYear,
                                                           monthId,
                                                           315,
                                                           goalData[currentYear][monthId][cdiLocationId]['incoming'].goal,
                                                           cdiLocationId,

                                                       )"
                                                   type="text"
                                                   v-model="goalData[currentYear][monthId][cdiLocationId]['incoming'].goal" />
                                        </div>
                                    </td>
                                    <td v-else class="number">
                                        {{goalData[currentYear][monthId][cdiLocationId]['incoming'].goal | currency }}
                                    </td>
                                    <td class="number">
                                        {{
                                            goalData[currentYear][monthId][cdiLocationId]['incoming'].goal / goalData[currentYear][monthId][cdiLocationId]['incoming'].days_in_month | currency
                                        }}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </template>
            </AccordionItem>
        </Accordion>
    </div>
</template>

<script>
import AccordionItem from "@/components/utils/AccordionItem";
import Accordion from "@/components/utils/Accordion";
import {store} from '@/store/Tool.store';
import cdiLocationCache from "@/cache/cdiLocation.cache";
import helpers from "@/store/helpers";
import dateHelpers from "@/store/DateHelpers";

export default {
    name: 'sales_goals',
    components: {
        AccordionItem,
        Accordion
    },
    data() {
        return {
            appState: this.$appStore.state,
            showDetails: false,
            goalData: [],
            location: 1,
            locationCount: cdiLocationCache.CDI_LOCATIONS.length + 1,
            locationGoals: {},
            showInstantiationButton: false,
            optionFields: {
                month: {val: new Date().getMonth() + 1, qsField: '[month]'},
                year: {val: new Date().getFullYear(), qsField: '[year]'},
                salesStaff: {val: 0, qsField: '[show_sales_user]'},
                cdiLocation: {val: '', qsField: '[show_location_goals]'},
                includeCdiStock: {val: '', qsField: '[include_cdi_stock]'},
                salesPersonId: {val: 0, qsField: '[tracker_sales_user]'}
            },
            months: dateHelpers.getMonthsArray(),
            cache: {
                cdiLocationCache
            },
            currentYear: new Date().getFullYear()
        }
    },
    created() {
        store.api('/sales_goals/month_goals', helpers.getQSPairs(this.optionFields)).then((data) => {
            this.goalData = data.data
            if (!Object.keys(this.goalData).length) {
                store.api('/sales_goals/year_instantiation', {'params[currentYear]': this.currentYear}).then(data => this.goalData = data.data)

            }
        });
        this.$appStore.setTitle(['Sales and Order Goals']);

    },
    methods: {
        save: function (year, month, type, goal, cdi) {
            let options = {
                'params[month]': month,
                'params[year]': year,
                'params[type]': type,
                'params[goal]': goal,
                'params[cdi_location_id]': cdi,
            }
            if (this.isActive) {
                store.api('/sales_goals/save_monthly_goal', options)
            }
        }

    },
    filters: {
        financial: function (value) {
            value = parseFloat(value).toFixed(2);
            return value;
        }
    },
    computed: {
        totalProd: function () {
            if (this.isEmpty) {
                return 0;
            }
            let total = [];
            let pre = 0;
            for (let month = 1; month < 13; month++) {
                for (let loc = 1; loc < this.locationCount; loc++) {
                    pre += parseFloat(this.goalData[this.currentYear][month][loc]['production'].goal);
                }
                total.push(pre);
                pre = 0;
            }
            return total;
        },
        totalInc: function () {
            if (this.isEmpty) {
                return 0;
            }
            let total = [];
            let pre = 0;
            for (let month = 1; month < 13; month++) {
                for (let loc = 1; loc < this.locationCount; loc++) {
                    pre += parseFloat(this.goalData[this.currentYear][month][loc]['incoming'].goal);
                }
                total.push(pre);
                pre = 0;
            }
            return total;
        },
        totalProdMonth: function () {
            if (this.isEmpty) {
                return 0;
            }
            let total = [];
            let pre = 0;
            for (let loc = 1; loc < this.locationCount; loc++) {
                for (let month = 1; month < 13; month++) {
                    pre += parseFloat(this.goalData[this.currentYear][month][loc]['production'].goal);
                }
                total.push(pre);
                pre = 0;
            }
            return total;
        },
        totalIncMonth: function () {
            if (this.isEmpty) {
                return 0;
            }
            let total = [];
            let pre = 0;
            for (let loc = 1; loc < this.locationCount; loc++) {
                for (let month = 1; month < 13; month++) {
                    pre += parseFloat(this.goalData[this.currentYear][month][loc]['incoming'].goal);
                }
                total.push(pre);
                pre = 0;
            }
            return total;
        },
        totalProdYear: function () {
            if (this.isEmpty) {
                return false;
            }
            let pre = 0;
            for (let loc = 1; loc < this.locationCount; loc++) {
                for (let month = 1; month < 13; month++) {
                    pre += parseFloat(this.goalData[this.currentYear][month][loc]['production'].goal);
                }
            }
            return pre;
        },
        totalIncYear: function () {
            if (this.isEmpty) {
                return 0;
            }
            let pre = 0;
            for (let loc = 1; loc < this.locationCount; loc++) {
                for (let month = 1; month < 13; month++) {
                    pre += parseFloat(this.goalData[this.currentYear][month][loc]['incoming'].goal);
                }
            }
            return pre;
        },
        isActive: function () {
            return this.$appStore.hasRole(this.$cdiVars.CDI_ROLE_LEADERSHIP);
        },
        isEmpty: function () {
            return !Object.keys(this.goalData).length;
        }


    }

}
</script>

<style scoped>

</style>